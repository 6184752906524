import { render, staticRenderFns } from "./SpeedGameLM.vue?vue&type=template&id=232b3005&scoped=true&"
import script from "./SpeedGameLM.vue?vue&type=script&lang=js&"
export * from "./SpeedGameLM.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232b3005",
  null
  
)

export default component.exports